<template>
  <div>
    <v-card>
      <v-card-title>
        Proxy
        <v-spacer> </v-spacer>
        <v-btn text color="success" @click="get()"> Atualizar </v-btn>
        <v-btn text color="success" @click="showForm = !showForm">
          Adicionar
        </v-btn>
      </v-card-title>
      <!-- FORM -->
      <FormProxy
        v-model="showForm"
        :item="item"
        @closeDialoag="closeDialoag"
        @save="save"
      />
    </v-card>
    <!-- TABELA -->
    <v-card class="mt-2">
      <v-simple-table dark>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Nome</th>
              <th class="text-left">Url</th>
              <th class="text-left" v-show="$store.getters.showGroups">
                Grupo
              </th>
              <th>Descrição</th>
              <th>Status</th>
              <th>Ativo</th>
              <th class="text-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in getProxys"
              :key="index"
              :class="getLineColor(item)"
            >
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <!-- ips -->
              <td>
                {{ item.url }}
              </td>
              <!-- grupo -->
              <td v-show="$store.getters.showGroups">
                {{ $store.getters.getGroupNameById(item.groupId) }}
              </td>
              <td>
                {{ item.description }}
              </td>
              <td>
                <tr>
                  <td>Online: {{ getCamerasWithStatusResume(item).online }}</td>
                </tr>
                <tr>
                  <td>
                    OffLine: {{ getCamerasWithStatusResume(item).offline }}
                  </td>
                </tr>
                <tr>
                  <td>Total: {{ getCamerasWithStatusResume(item).total }}</td>
                </tr>
              </td>
              <!-- tipo de monitoramento -->
              <td>
                {{ item.enabled ? "Sim" : "Não" }}
              </td>
              <td class="text-center">
                <!-- ver monitoramento -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="openVerProxy(item)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <!-- editar monitoramento -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="edit(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <!-- excluir monitoramento -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="del(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Excluir</span>
                </v-tooltip>
                <!-- abrir proxy -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="openProxy(item)"
                    >
                      <v-icon>mdi-google-chrome</v-icon>
                    </v-btn>
                  </template>
                  <span>Abrir Proxy</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <VerProxy :item="item" v-model="verProxy" :status="statusProxy" />
    </v-card>
  </div>
</template>

<script>
import VerProxy from "./components/VerProxy.vue";
import FormProxy from "./components/FormProxy.vue";
import { mapGetters } from "vuex";
export default {
  name: "Proxys",
  components: {
    VerProxy,
    FormProxy,
  },
  data: () => {
    return {
      ip: "",
      showForm: false,
      dialog: false,
      verProxy: false,
      item: {
        name: "",
        groupId: 0,
        description: "",
        url: "",
        secret: "",
        enabled: 1,
      },
      statusProxy: [],
      ws: null,
    };
  },
  methods: {
    get() {
      this.$store.dispatch("loadProxys");
      this.$store.dispatch("loadProxysStatus");
      this.$store.dispatch("loadGrupos");
    },
    closeDialoag() {
      this.item = {};
      this.showForm = false;
    },
    openDialoag(item) {
      if (item) {
        this.item = item;
      }
      this.dialog = true;
    },
    openVerProxy(item) {
      if (item) {
        this.item = item;
      }
      this.statusProxy = this.getCamerasWithStatus(item);
      this.verProxy = true;
    },
    save(item) {
      this.$store.dispatch("saveProxy", item);
      this.closeDialoag();
    },
    del(item) {
      this.$store.dispatch("deletProxy", item);
    },
    edit(item) {
      this.showForm = true;
      this.item = item;
      this.$vuetify.goTo(0, { easing: "easeInOutCubic", duration: 300 });
    },
    getLineColor(item) {
      if (!item.enabled) {
        return "";
      }
      let prx = this.getProxyStatusById(item.id);
      if (prx) {
        return prx.connected ? "green darken-4" : "red darken-4";
      }
    },
    getCamerasWithStatus(item) {
      if (!item.enabled) {
        return "";
      }
      let prx = this.getProxyStatusById(item.id);
      if (!prx.cameras) return [];
      let cameras = [];
      if (prx) {
        cameras = prx.cameras;
        cameras.forEach((el) => {
          let sts = prx.camerasStatus.find((sts) => sts.id == el.id);
          el.status = sts ? sts.status : {};
        });
      }
      return cameras;
    },
    getCamerasWithStatusResume(item) {
      let cameras = this.getCamerasWithStatus(item);
      if (!cameras) return { online: 0, offline: 0, total: 0 };
      let online = cameras.filter((el) => el.status.conected);

      return {
        online: online.length,
        offline: cameras.length - online.length,
        total: cameras.length,
      };
    },
    openProxy(item) {
      let win = window.open(item.url, "_blank");
      win.focus();
    },
    updateStatus(event) {
      let e = JSON.parse(event.data);
      if (e.eventName == "cameraStatus") {
        let sts = this.$store.getters.getProxysStatus;
        let prx = sts.findIndex((el) => el.id == e.proxyId);
        if (prx > -1) {
          sts[prx].camerasStatus = e.data;
          this.$store.dispatch("setProxysStatus", sts);
        }
      }
      if (e.eventName == "disconnect") {
        let sts = this.$store.getters.getProxysStatus;
        let prx = sts.findIndex((el) => el.id == e.proxyId);
        if (prx > -1) {
          sts[prx].connected = false;
          this.$store.dispatch("setProxysStatus", sts);
        }
      }
      if (e.eventName == "connect") {
        let sts = this.$store.getters.getProxysStatus;
        let prx = sts.findIndex((el) => el.id == e.proxyId);
        if (prx > -1) {
          sts[prx].connected = true;
          this.$store.dispatch("setProxysStatus", sts);
        }
      }
    },
    startWebSocket() {
      console.log("start websocket");
      this.wsUrl = "";
      if (process.env.NODE_ENV === "production") {
        this.wsUrl =
          `${window.location.protocol}//${window.location.host}/api/proxy/`
            .replace("http", "ws")
            .replace("https", "wss");
      } else {
        this.wsUrl = `${this.$http.defaults.baseURL}proxy/`
          .replace("http", "ws")
          .replace("https", "wss");
      }
      this.wsUrl += this.$store.getters.getImgTokenQuery;
      this.ws = new WebSocket(this.wsUrl);
      this.ws.addEventListener("open", () => {
        this.ws.send(
          JSON.stringify([
            { subscribe: "disconnect" },
            { subscribe: "connect" },
            { subscribe: "cameraStatus" },
          ])
        );
        console.log("ws open");
      });
      this.ws.onmessage = this.updateStatus;
      this.ws.onclose = () => {
        console.log("ws closed");
        this.disconectWebSocket();
        setTimeout(() => {
          this.startWebSocket();
        }, 5000);
      };
    },
    disconectWebSocket() {
      this.ws.removeEventListener("message", this.updateStatus);
      this.ws.onclose = () => {};
      this.ws.close();
    },
  },
  computed: {
    ...mapGetters(["getProxyStatusById"]),
    getProxys: {
      get() {
        return this.$store.getters.getProxys;
      },
      set() {},
    },
  },
  mounted() {
    this.get();
    this.startWebSocket();
  },
  beforeRouteLeave(to, from, next) {
    if (this.ws) {
      console.log("close websocket");
      this.ws.removeEventListener("message", this.updateStatus);
      this.ws.onclose = () => {};
      this.ws.close();
    }
    next();
  },
};
</script>

<style>
</style>